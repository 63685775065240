import React, { useContext, useEffect, useState } from "react";
import LineTextarea from "../../Pages/Shared/LineTextarea/LineTextarea";
import CustomCheckbox from "../../Pages/Shared/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import phone_message from "../../assets/phone_with_message.png";
import CustomInputFile from "../../Pages/Shared/CustomInputFile/CustomInputFile";
import { enqueueSnackbar } from "notistack";
import {
  useGetAllCoursesByInstrutorIdQuery,
  useSendNotificationByCourseIdMutation,
} from "../../redux/ReduxToolkit";
import { AuthContext } from "../../Providers/AuthProvider";
import isJwtTokenExpired, { decode } from "jwt-check-expiry";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import CustomLoader from "../Loading/Loading";

function CreateNotificaton() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("select course");
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const { accessToken, userData } = useContext(AuthContext);
  const [selectCourseId, setSelectCourseId] = useState(null);

  //   console.log("educator id = ", userData?.userInfo?._id);

  const { data: allCourse, isLoading: allCourseIsLoading } =
    useGetAllCoursesByInstrutorIdQuery({
      id: userData?.userInfo?._id,
      token: accessToken,
    });

  const [
    sendNotificationByCourseId,
    { isLoading: sendNotificationByCourseIdIsLoading },
  ] = useSendNotificationByCourseIdMutation();

  useEffect(() => {
    if (selectCourseId === null) {
      setDropdownValue("select course");
    }
  });

  const handleFormSubmit = async () => {
    if (!selectCourseId) {
      enqueueSnackbar(" Please select course !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (title === null || title === undefined || title.length === 0) {
      enqueueSnackbar(" Title is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    if (
      description === null ||
      description === undefined ||
      description.length === 0
    ) {
      enqueueSnackbar(" Description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    // console.log("title", title);
    // console.log("description", description);
    // console.log("image ", image);
    // console.log("courseId ", selectCourseId);

    try {
      const response = await sendNotificationByCourseId({
        body: {
          title: title,
          body: description,
          image: image,
          courseId: selectCourseId,
        },
        token: accessToken,
      });

      if (response?.data) {
        // console.log("api response = ", response?.data?.msg);
        enqueueSnackbar(response?.data?.msg, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setTitle("");
        setDescription("");
        setShowImageUploader(false);
        setSelectCourseId(null);
      } else {
        // console.log(response?.error?.data?.msg);
        enqueueSnackbar(response?.error?.data?.msg, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setTitle("");
        setDescription("");
        setShowImageUploader(false);
        setSelectCourseId(null);
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const handleUploadFile = async (e) => {
    console.log("file path", e.target.files[0]?.name);

    // let formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // formData.append("userId", "test-user-id");

    setImage(e.target.files[0]);

    // console.log("Formdata", formData.entries());

    // try {
    // 	const uploadThumbnailResponse = await uploadCourseThumbnail({
    // 		body: formData,
    // 	});

    // 	setUploadThumbnailResponse(uploadThumbnailResponse?.data);
    // 	// console.log("uploadThumbnailResponse", uploadThumbnailResponse?.data);
    // } catch (error) {
    // 	console.error("Mutation failed:", error.message);
    // }
  };
  return (
    <div className="flex flex-col lg:flex-row justify-center items-start shadow-lg mb-5 rounded-2xl lg:p-10 ">
      {(allCourseIsLoading || sendNotificationByCourseIdIsLoading) && (
        <CustomLoader />
      )}
      <div className="lg:w-[50%] pe-10 hidden lg:block">
        <img src={phone_message} alt="" className="w-[3rem] mb-10" />
        <p className=" text-xl  font-semibold text-[#313639] mb-10">
          Personalize Notification
        </p>
        <p className="text-sm text-gray-500 mb-20 w-[80%]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          recusandae laborum excepturi eaque quisquam similique nostrum quod
          doloribus qui totam eos, soluta minus nam voluptatum magni cupiditate
          reiciendis? Rem, mollitia.
        </p>
        <div className="bg-green-500 h-[3px] w-[5rem] "></div>
      </div>
      <div className="w-full lg:w-[50%] ">
        <div className="bg-neutral-100 p-5 lg:p-10 rounded-2xl">
          <div className=" relative mb-8">
            <div
              className="border rounded-md px-3 py-2  flex justify-between items-center cursor-pointer text-gray-800 bg-white "
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <p className=" capitalize">{dropdownValue} </p>

              <MdOutlineKeyboardArrowDown
                className={`text-2xl duration-300 ${
                  showDropdown ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
            {showDropdown && (
              <ul className=" shadow-lg bg-white py-3 mx-1 absolute left-0 right-0 z-10 overflow-y-auto h-[10rem]  text-gray-800">
                {allCourse?.courses?.map((courseData, index) => (
                  <li
                    key={index}
                    className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      {
                        setDropdownValue(courseData?.courseName);
                        setSelectCourseId(courseData?._id);
                        // setSelectCourseId("656b26e27985967d4b79b042");
                        setShowDropdown(false);
                      }
                    }}
                  >
                    {courseData?.courseName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className=" mb-8">
            <LineTextarea
              inputLable={`Title`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className=" mb-8">
            <LineTextarea
              inputLable={`Description`}
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className=" mb-8 ps-1">
            <CustomCheckbox
              inputLable={`Click on the checkbox to upload the image`}
              onChange={(e) => setShowImageUploader(e.checked)}
              checked={showImageUploader}
            />
          </div>

          {showImageUploader && (
            <div className={` mb-8 `}>
              {/* <CustomInputFile onChange={(e) => handleUploadFile(e)} /> */}
              <input
                type="file"
                onChange={(e) => handleUploadFile(e)}
                className=""
              />
            </div>
          )}
          <CustomButton
            lable={`Send Notification`}
            className={`w-full bg-green-500 text-white h-[3rem] border-none !m-0`}
            onClick={() => handleFormSubmit()}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateNotificaton;
